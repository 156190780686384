import { motion, Variants } from "framer-motion";
import { ComponentProps, ReactNode } from "react";
import { useAppDispatch, useAppSelector } from "../../../../hooks/hooks";
import {
  selectMenuModal,
  setModal,
} from "../../../data-access/store/ui/uiSlice";
import Menu from "../../../../menu/feature/menu";
import { StatsChip, StatsChipsEnum } from "../../stats-chip";
import { Player } from "../../player";
import "./navbar.scss";
import {
  selectToken,
  selectUser,
} from "../../../../auth/data-access/store/authSlice";
import {
  selectLanguage,
  selectTranslations,
} from "../../../data-access/store/general/generalSlice";
import { checkTranslationKey } from "../../../../utils/translation";
// import SoundManager from "../../../../utils/managers/sound-manager";
import { Link } from "react-router-dom";

export interface NavBarProps extends ComponentProps<"div"> {
  navbarType?: "standard" | "full";
  hideMenu?: boolean;
  hasStats?: boolean;
  hasAvatar?: boolean;
  slotEnd?: "level" | "lives" | ReactNode;
  slotBeforeLogo?: ReactNode;
}

const menuVariants: Variants = {
  hidden: {
    y: "-100%",
  },
  visible: {
    y: 0,
    transition: {
      duration: 0.3,
      ease: "easeOut",
    },
  },
};

export function NavBar({
  navbarType = "standard",
  children,
  hideMenu,
  hasStats,
  hasAvatar,
  slotEnd,
  slotBeforeLogo,
  ...props
}: NavBarProps) {
  const dispatch = useAppDispatch();
  const menuModal = useAppSelector(selectMenuModal);
  const user = useAppSelector(selectUser);
  const lang = useAppSelector(selectLanguage);
  const translations = useAppSelector(selectTranslations);
  const token = useAppSelector(selectToken);
  const isGuest = token === process.env.REACT_APP_GUEST_KEY;

  // const [livesChanged, setLivesChanged] = useState<boolean>(false);
  // const isInitialMount = useRef(true);
  // useEffect(() => {
  //   if (isInitialMount.current) {
  //     isInitialMount.current = false;
  //   } else if (!isInitialMount.current && user?.character.skills.lives) {
  //     setLivesChanged(true);
  //     SoundManager.vfxSounds.life.play();
  //
  //     setTimeout(() => {
  //       setLivesChanged(false);
  //     }, 800);
  //   }
  // }, [user?.character.skills.lives]);

  const slotEndChip = (value: string | ReactNode) => {
    switch (value) {
      case "lives":
        return (
          <StatsChip
            type={StatsChipsEnum.LIVES}
            value={isGuest ? "∞" : user?.character.skills.lives}
          />
        );
      case "level":
        return (
          <StatsChip
            type={StatsChipsEnum.LEVEL}
            value={user?.character.level.current}
          />
        );
      default:
        return value;
    }
  };

  return (
    <>
      <motion.div
        id="navbar"
        variants={menuVariants}
        initial="hidden"
        animate="visible"
      >
        <div
          {...props}
          className={`menu menu-${navbarType} ${props.className ?? ""} ${
            hasStats ? "has-stats" : ""
          } ${slotBeforeLogo ? "has-slot-before-logo" : ""}`}
        >
          <div className="slot-start">
            {!hideMenu && !isGuest ? (
              <img
                src="/img/menu.png"
                alt={checkTranslationKey(translations[lang]?.Menu, "Menu")}
                width="30px"
                onClick={() => {
                  dispatch(setModal({ modal: "menu", value: true }));
                }}
              />
            ) : null}
          </div>
          {slotBeforeLogo && (
            <div className="slot-before-logo">{slotBeforeLogo}</div>
          )}
          <div className="slot-logo">
            <img
              src="/img/batteup-logo-2x.png"
              alt={checkTranslationKey(
                translations[lang]?.BattleUp_Logo,
                "BattleUp Logo"
              )}
              className="logo"
            />
          </div>
          <div className="slot-end">
            {slotEnd ? (
              slotEndChip(slotEnd)
            ) : hasAvatar ? (
              <Link to="/profile/edit" className="avatar-container">
                <Player hasShadow={false} avatar={user?.character.avatar} />
              </Link>
            ) : null}
            {/*<AnimatePresence>*/}
            {/*  {livesChanged && (*/}
            {/*    <motion.span*/}
            {/*      transition={{ duration: 0.8 }}*/}
            {/*      initial={{ opacity: 0 }}*/}
            {/*      animate={{ opacity: 1 }}*/}
            {/*      exit={{ opacity: 0 }}*/}
            {/*      className={`minus-life ${livesChanged ? "active" : ""}`}*/}
            {/*    >*/}
            {/*      -1*/}
            {/*    </motion.span>*/}
            {/*  )}*/}
            {/*</AnimatePresence>*/}
          </div>
        </div>
        {navbarType === "full" ? (
          <div className="rewards-area">{children}</div>
        ) : null}
        {hasStats && user ? (
          <div className="stats-header">
            <StatsChip
              type={StatsChipsEnum.LEVEL}
              value={user?.character.level.current}
            />
            <StatsChip
              type={StatsChipsEnum.POINTS}
              value={user?.character.points}
            />
            <StatsChip
              type={StatsChipsEnum.COINS}
              value={
                user?.eligibility.credits.purchased +
                user?.eligibility.credits.subscription
              }
            />
            <StatsChip
              type={StatsChipsEnum.LIVES}
              value={isGuest ? "∞" : user?.character.skills.lives}
            />
          </div>
        ) : null}
      </motion.div>

      <div className={`modal-menu ${menuModal ? "open" : ""}`}>
        <Menu />
      </div>
    </>
  );
}

export default NavBar;
