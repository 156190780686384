import { createSelector, createSlice } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";
import { generalApi } from "./services/general.service";
import { RootState } from "../store";
import { InitResponse } from "../../../interfaces/general.types";
import { Translations } from "../../../interfaces/translations.type";

let uuid = localStorage.getItem("uuid");
const lang = localStorage.getItem("lang");

if (!uuid) {
  uuid = uuidv4();
  localStorage.setItem("uuid", uuid);
}

type GeneralState = {
  uuid: string;
  language: string | "";
  init: InitResponse | null;
  translations: Translations;
  showWelcomeModal: boolean;
};

const initialState: GeneralState = {
  uuid: uuid,
  language: lang || "",
  init: null,
  translations: { en: { initial: "" } },
  showWelcomeModal: false,
};

const generalSlice = createSlice({
  name: "general",
  initialState,
  reducers: {
    updateLanguage: (state, action) => {
      state.language = action.payload.language;
    },
    updateShowWelcomeModal: (state, action) => {
      state.showWelcomeModal = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      generalApi.endpoints.init.matchFulfilled,
      (state, { payload }) => {
        if (!state.language) {
          state.language = payload.i18n.defaultLanguage;
        }

        state.init = payload;
      }
    );
    builder.addMatcher(
      generalApi.endpoints.getTranslations.matchFulfilled,
      (state, { payload }) => {
        state.translations = payload;
      }
    );
  },
});

const { reducer, actions } = generalSlice;

export const { updateLanguage, updateShowWelcomeModal } = actions;

export const selectGeneralState = (state: RootState) => state.general;

export const selectInit = createSelector(
  selectGeneralState,
  (state) => state.init
);
export const selectLanguage = (state: RootState) => state.general.language;
export const selectTranslations = (state: RootState) =>
  state.general.translations;
export const selectShowWelcomeBackModal = (state: RootState) =>
  state.general.showWelcomeModal;

export const selectUserId = createSelector(
  selectGeneralState,
  (state: GeneralState) => state.uuid
);

export default reducer;
