import { Background } from "../../shared/ui/background";
import { LadderButton } from "../../shared/ui/ladder-button";
import { NavBar } from "../../shared/ui/navbar";
import "./ladder.scss";
import { Avatar } from "../../shared/ui/avatar";
import { Badge } from "../../shared/ui/badge";
import { InitResponse } from "../../shared/interfaces/general.types";
import { useEffect, useMemo, useRef, useState } from "react";
import cx from "classnames";
import { ButtonProps } from "../../shared/ui/button";
import { motion, Variants } from "framer-motion";
import { useLocation, useNavigate } from "react-router-dom";
import { useGameEvents } from "../../shared/data-access/store/game/hooks/use-game-events";
import { Game } from "../../shared/data-access/store/game/types/game-types";
import { LadderEvent } from "../../shared/data-access/store/game/types/socket-events/ladder";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import { setMessage } from "../../shared/data-access/store/message/messageSlice";
import { LadderCushionSafe } from "../../shared/ui/modal-notifications/ladder-cushion-safe";
import {
  FeedbackType,
  LadderFeedback,
} from "../../shared/ui/modal-notifications/ladder-feedback";
import {
  selectToken,
  selectUser,
} from "../../auth/data-access/store/authSlice";
import useLocals from "../../utils/hooks/use-locals";
import {
  addSeconds,
  differenceInMilliseconds,
  differenceInSeconds,
} from "date-fns";
import { useLazyGetUserQuery } from "../../auth/data-access/store/services/auth.service";
import {
  selectOpponentLost,
  updateOpponentLost,
} from "../../shared/data-access/store/game/gameSlices";
import SoundManager from "../../utils/managers/sound-manager";
import { selectLanguage } from "../../shared/data-access/store/general/generalSlice";
import {
  selectTutorial,
  setTutorial,
} from "../../shared/data-access/store/ui/uiSlice";

type LadderPointsKeys = keyof InitResponse["games"]["ladder_points"];
type LadderPointsValues =
  InitResponse["games"]["ladder_points"][LadderPointsKeys];

const mockAvatar =
  "https://images.unsplash.com/photo-1564564321837-a57b7070ac4f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1176&q=80";

const variantsPlayer: Variants = {
  hidden: {
    opacity: 0,
    scale: 0,
  },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      duration: 0.3,
      delay: 0,
    },
  },
};

const variantsCushions: Variants = {
  hidden: {
    opacity: 0,
    scale: 0,
  },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      duration: 0.3,
      delay: 0.3,
      scale: {
        type: "spring",
        bounce: 0.7,
      },
    },
  },
};

const variantsRing1: Variants = {
  initial: {
    rotate: -13,
  },
  final: {
    rotate: 0,
    scale: [2, 1.3],
    transition: {
      scale: {
        duration: 2,
        times: [0, 1],
      },
      rotate: {
        duration: 0.3,
        delay: 1,
      },
    },
  },
};

const variantsRing2: Variants = {
  initial: {
    rotate: 8,
  },
  final: {
    rotate: 0,
    scale: [2, 1.3],
    transition: {
      scale: {
        duration: 2,
        times: [0, 1],
      },
      rotate: {
        duration: 0.3,
        delay: 1,
      },
    },
  },
};

const ladderTimeoutInSeconds = 60;

export function Ladder() {
  const {
    error,
    data: ladderResponse,
    game,
    send,
    meta,
    resetState: resetLadderState,
  } = useGameEvents("get_ladder");
  const { send: updateAnswer } = useGameEvents("update_round_answer");
  const { data: finishGameResponse, send: finishGame } =
    useGameEvents("finish_game");
  const { data: opponentData } = useGameEvents("get_opponent");
  const dispatch = useAppDispatch();
  const { uuid, token } = meta;
  const user = useAppSelector(selectUser);
  const opponentAvatar = opponentData?.data?.opponent.avatar;
  const [getUser] = useLazyGetUserQuery();
  const tokenUser = useAppSelector(selectToken);
  const isGuest = tokenUser === process.env.REACT_APP_GUEST_KEY;
  const lang = useAppSelector(selectLanguage);

  const ladderData = ladderResponse?.data;
  const prizeRef = useRef(finishGameResponse?.data?.prizes.prize);
  const isGameFinishedRef = useRef(game?.finished);
  const isLostRef = useRef(false);

  const ladderPoints = game?.ladder_points;
  const navigate = useNavigate();
  const location = useLocation();
  const [currentItem, setCurrentItem] = useState(0);
  const [playersPosition, setPlayersPosition] = useState([0, 0]);
  const ladderContainerRef = useRef<HTMLDivElement>(null);
  const ladderButtonRef = useRef<Array<HTMLElement | null>>([]);
  const [pendingResults, setPendingResults] = useState(true);
  const [readyToPlay, setReadyToPlay] = useState(false);
  const id = game?.id;
  const [showCushionSafe, setShowCushionSafe] = useState(false);
  const [showRings, setShowRings] = useState(false);
  const [canPositionRings, setCanPositionRings] = useState(false);
  const [showLadderFeedback, setShowLadderFeedback] = useState(false);
  const [ladderFeedbackType, setLadderFeedbackType] =
    useState<null | FeedbackType>(null);
  const [opponentCanAdvance, setOpponentCanAdvance] = useState(true);
  const opponentLost = useAppSelector(selectOpponentLost);
  // const [playerLastCushion, setPlayerLastCushion] = useState(0);
  // const [opponentLastCushion, setOpponentLastCushion] = useState(0);
  const tutorial = useAppSelector(selectTutorial);
  const [tutorialShowPrev, setTutorialShowPrev] = useState(tutorial.show);
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  const ladderItemsKeys: Array<LadderPointsKeys> | [] = ladderPoints
    ? Object.keys(ladderPoints)
    : [];
  const sortedLadderItemsKeys = ladderItemsKeys.sort((a, b) => {
    return Number(a) > Number(b) ? -1 : 1;
  });
  const sortedLadderItems: Array<LadderPointsValues> =
    sortedLadderItemsKeys.map((sortedKey) => {
      // @ts-ignore
      if (ladderPoints && ladderPoints[sortedKey]) {
        // @ts-ignore
        return ladderPoints[sortedKey];
      }
    });

  // Get the position of the 2 cushions
  const cushions = ladderPoints
    ? ladderPoints.filter((item) => item.cushion)
    : undefined;
  const firstCushionPosition =
    cushions && ladderPoints ? ladderPoints.indexOf(cushions[0]) : 0;
  const secondCushionPosition =
    cushions && ladderPoints ? ladderPoints.indexOf(cushions[1]) : 0;

  const commonParams = useMemo(() => {
    if (!game?.id) {
      return false;
    }
    return {
      playedGame: {
        id: game.id,
      },
      userToken: token,
      uuid,
    };
  }, [game?.id, token, uuid]);

  const ladderExpirationTs = {
    get: () => getValue("ladderExpTs"),
    set: (date: Date) => setLocalsValue("ladderExpTs", date.toISOString()),
    clean: () => setLocalsValue("ladderExpTs", null),
  };

  const handleForceExpiration = async () => {
    if (!commonParams) {
      return;
    }
    await finishGame({ ...commonParams, force_finish: true });
    dispatch(
      setMessage({
        content: "Oops, this game expired.",
        type: "error",
      })
    );
    setTimeout(() => {
      navigate("/");
    }, 5000);
  };

  useEffect(() => {
    if (!SoundManager.ambientSounds.opponent.paused) {
      SoundManager.ambientSounds.opponent.muted = true;
      SoundManager.ambientSounds.opponent.pause();
    }
  }, []);

  useEffect(() => {
    if (tutorial.show) {
      ladderExpirationTs.clean();
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    }

    if (!tutorial.show && tutorialShowPrev) {
      const newExpirationDate = addSeconds(new Date(), ladderTimeoutInSeconds);
      ladderExpirationTs.set(newExpirationDate);

      timeoutRef.current = setTimeout(() => {
        handleForceExpiration();
      }, differenceInMilliseconds(newExpirationDate, new Date()));
    }

    setTutorialShowPrev(tutorial.show);
  }, [tutorial.show]);

  // ladder expiration effect
  useEffect(() => {
    if (!commonParams) {
      return;
    }

    const expirationLocals = ladderExpirationTs.get();
    let expirationDate = expirationLocals ? new Date(expirationLocals) : null;
    if (!expirationDate) {
      const newExpirationDate = addSeconds(new Date(), ladderTimeoutInSeconds);
      ladderExpirationTs.set(newExpirationDate);
      expirationDate = newExpirationDate;
    }

    timeoutRef.current = setTimeout(() => {
      ladderExpirationTs.clean();
      handleForceExpiration();
    }, differenceInMilliseconds(expirationDate, new Date()));

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [commonParams]);

  useEffect(() => {
    prizeRef.current = finishGameResponse?.data?.prizes.prize;
  }, [finishGameResponse]);

  useEffect(() => {
    isGameFinishedRef.current = game?.finished;
  }, [game?.finished]);

  useEffect(() => {
    // @ts-ignore
    if (location?.state?.shouldFinish && commonParams) {
      finishGame(commonParams);
    }

    return () => {
      // @ts-ignore
      if (!location?.state?.shouldFinish) {
        resetLadderState();
      }
    };
  }, [location, commonParams]);

  const { getValue, setValue: setLocalsValue } = useLocals();

  const handleExpiredAnswer = async () => {
    if (!commonParams) {
      return;
    }

    await updateAnswer({
      ...commonParams,
      answer: -1,
      countdown: -1,
      playedGame: {
        ...commonParams.playedGame,
        current_round_index: game!.current_round_index,
      },
    });
  };
  useEffect(() => {
    if (!commonParams) {
      return;
    }

    const expTimer = getValue("timerExpTs");

    if (expTimer) {
      const interval = setInterval(async () => {
        if (expTimer) {
          if (differenceInSeconds(new Date(expTimer), new Date()) < 0) {
            if (!isGameFinishedRef.current) {
              clearInterval(interval);

              try {
                await handleExpiredAnswer();
                isLostRef.current = true;
                await finishGame(commonParams);
                setLadderFeedbackType(FeedbackType.Lost);
                setShowLadderFeedback(true);
                setTimeout(async () => {
                  await getUser().unwrap();
                  goToResults();
                }, 3000);
              } catch (e) {
                handleForceExpiration();
              }
            }
          }
        }
      }, 1000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [commonParams]);

  // useEffect(() => {
  //   if (!gameId) {
  //     navigate("/");
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  useEffect(() => {
    //@TODO: Check for this message to see what it does.
    if (error) {
      dispatch(
        setMessage({ content: "Oops, someting went wrong", type: "error" })
      );
    }
  }, [error, dispatch]);

  useEffect(() => {
    if (uuid && token && id) {
      send({
        playedGame: {
          id,
        },
        uuid,
        userToken: token,
      });
    }
  }, [send, token, id, uuid]);

  useEffect(() => {
    if (ladderData && game) {
      // Set current item with previous round index
      setCurrentItem(
        adjustIndexToDescOrder(
          getPrevRoundIndex(ladderData.current_round_index),
          game.total_rounds
        )
      );

      // Set initial player position
      setPlayersPositionFromLadder(ladderData.finished ? false : true, true);

      // If opponent lost, store it in global state to use it later
      if (!ladderData.opponent.can_advance && !opponentLost) {
        dispatch(updateOpponentLost(true));
      }

      setTimeout(() => {
        // Initial state of button is pending results, set it to true after 2 seconds
        setPendingResults(false);

        // If this is not the first round, set the position of players according to current round index
        if (ladderData.current_round_index > 0 && !ladderData.finished) {
          if (ladderData.player.can_advance) {
            SoundManager.vfxSounds.climb_up.play();
          } else {
            SoundManager.vfxSounds.climb_down.play();
          }
          setPlayersPositionFromLadder(false);
        }
      }, 2000);

      setTimeout(
        () => {
          if (ladderData.player?.can_advance) {
            setCurrentItem(
              adjustIndexToDescOrder(
                ladderData.current_round_index,
                game.total_rounds
              )
            );
            setTimeout(() => {
              if (!ladderData.finished) {
                setReadyToPlay(true);
              }
            }, 500);
          }
          checkLadderStatusMsg(ladderData, game);
        },
        ladderData.current_round_index === 0 ? 0 : 2500
      );
    }

    return () => {
      if (ladderData && ladderData.finished) {
        dispatch(updateOpponentLost(false));
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ladderData]);

  useEffect(() => {
    if (!ladderData?.opponent.can_advance) {
      setOpponentCanAdvance(false);
    } else {
      setOpponentCanAdvance(true);
    }
  }, [ladderData?.opponent.can_advance]);

  const setPlayersPositionFromLadder = (
    prevValue: boolean,
    initial?: boolean
  ) => {
    if (game && ladderData) {
      const prevPlayerPos =
        ladderData.player.position - 1 < 0 ? 0 : ladderData.player.position - 1;
      const prevOpponentPos =
        ladderData.opponent.position - 1 < 0
          ? 0
          : ladderData.opponent.can_advance
          ? ladderData.opponent.position - 1
          : ladderData.opponent.position;

      const playerPos = initial
        ? ladderData.player.position
        : getPlayerPosBasedOnResult({
            lastCushionSavedPos: getPlayerLastCushion(
              ladderData.player.position
            ),
            playerWon: ladderData.player.can_advance,
            playerPos: ladderData.player.position,
          });

      const opponentPos =
        initial &&
        ladderData.opponent.position === ladderData.current_round_index
          ? ladderData.opponent.position
          : getPlayerPosBasedOnResult({
              lastCushionSavedPos: getPlayerLastCushion(
                ladderData.opponent.position
              ),
              playerWon: ladderData.opponent.can_advance,
              playerPos: ladderData.opponent.position,
            });

      const leftPlayerPosRef =
        ladderButtonRef.current[
          adjustIndexToDescOrder(
            prevValue ? prevPlayerPos : playerPos,
            game.total_rounds
          )
        ];

      const rightPlayerPosRef =
        ladderButtonRef.current[
          adjustIndexToDescOrder(
            prevValue && !opponentLost ? prevOpponentPos : opponentPos,
            game.total_rounds
          )
        ];

      if (leftPlayerPosRef !== null && rightPlayerPosRef !== null) {
        // A hacky way to avoid the difference in offsetTop compared with initial set
        const extraPaddingTop = initial ? 5 : 0;
        setPlayersPosition([
          getTopFromMiddleOfElement(leftPlayerPosRef) + extraPaddingTop,
          getTopFromMiddleOfElement(rightPlayerPosRef) + extraPaddingTop,
        ]);
      }
    }
  };

  const getPlayerLastCushion = (playerPos: number) => {
    let newPlayerLastCushion = 0;
    if (ladderData) {
      if (playerPos > secondCushionPosition) {
        newPlayerLastCushion = secondCushionPosition;
      } else if (playerPos > firstCushionPosition) {
        newPlayerLastCushion = firstCushionPosition;
      }
    }
    return newPlayerLastCushion;
  };

  const getPlayerPosBasedOnResult = ({
    lastCushionSavedPos,
    playerWon,
    playerPos,
  }: {
    lastCushionSavedPos: number;
    playerWon: boolean;
    playerPos: number;
  }) => {
    let newPlayerPos = playerPos;

    if (!playerWon) {
      newPlayerPos = lastCushionSavedPos;
    }

    return newPlayerPos;
  };

  const getPrevRoundIndex = (currentIndex: number) => {
    const prevIndex = currentIndex === 0 ? currentIndex : currentIndex - 1;
    return prevIndex;
  };

  const adjustIndexToDescOrder = (index: number, total: number) => {
    // console.log("adjustIndexToDescOrder", index, total);
    return total - 1 - index;
  };

  const getTopFromMiddleOfElement = (
    element: HTMLElement,
    relativeToWindow?: boolean
  ) => {
    const elementHeight = element?.clientHeight;
    const avatarHeight = 40;
    let top = 0;
    if (!relativeToWindow) {
      top = (elementHeight - avatarHeight) / 2 + element.offsetTop + 5;
    } else {
      // TODO: update values dynamically
      top = (elementHeight - 33) / 2 + element.getBoundingClientRect().top + 5;
    }

    return top;
  };

  useEffect(() => {
    if (readyToPlay && ladderData && ladderData.current_round_index > 0) {
      setTimeout(() => {
        setPlayersPositionFromLadder(false);
      }, 550);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [readyToPlay]);

  const handlePlay = () => {
    const currentIndex = ladderData?.current_round_index ?? 0;
    const totalRounds = game?.total_rounds ?? 100;

    ladderExpirationTs.clean();

    if (currentIndex % 3 === 0 && currentIndex !== totalRounds - 1) {
      navigate(`/category/`);
    } else {
      navigate(`/gameplay/classic`);
    }
  };

  const goToResults = () => {
    if (isGuest) {
      navigate("/auth/subscribe-guest");
    } else {
      navigate("/results");
    }
  };

  const checkLadderStatusMsg = async (
    checkedLadderData: LadderEvent["response"],
    checkedInitGameData: Game
  ) => {
    if (isLostRef.current) {
      return;
    }

    let feedbackInProgress = 0;
    const feedbackAnimationTimeout = 4000;

    if (
      checkedLadderData.player.can_advance &&
      checkedLadderData.current_round_index > 0 &&
      checkedLadderData.current_round_index - 1 ===
        checkedLadderData.opponent.position &&
      !checkedLadderData.opponent.can_advance
    ) {
      feedbackInProgress++;
      setShowLadderFeedback(true);
      setLadderFeedbackType(FeedbackType.Great);
      setTimeout(() => {
        setLadderFeedbackType(null);
        setShowLadderFeedback(false);
      }, 4000);
    }

    if (!checkedLadderData.player.can_advance) {
      if (!game) {
        return;
      }
      feedbackInProgress++;
      setTimeout(async () => {
        await getUser().unwrap();
        goToResults();
      }, 5000);
      if (firstCushionPosition) {
        setPlayersPositionFromLadder(false);
      }
      setShowLadderFeedback(true);
      setLadderFeedbackType(
        getPlayerLastCushion(checkedLadderData.player.position) > 0
          ? FeedbackType.SafePoints
          : FeedbackType.Lost
      );
      setTimeout(() => {
        setLadderFeedbackType(null);
        setShowLadderFeedback(false);
      }, feedbackAnimationTimeout);
    } else if (
      checkedLadderData.current_round_index ===
        checkedInitGameData.total_rounds - 1 &&
      checkedLadderData.finished
    ) {
      feedbackInProgress++;
      setShowLadderFeedback(true);
      setLadderFeedbackType(FeedbackType.Strike);
      setTimeout(() => {
        setLadderFeedbackType(null);
        setShowLadderFeedback(false);
        if (prizeRef.current?.type === "instant") {
          setTimeout(() => {
            setShowLadderFeedback(true);
            setLadderFeedbackType(FeedbackType.InstantWin);
            setTimeout(() => {
              setLadderFeedbackType(null);
              setShowLadderFeedback(false);
              setTimeout(async () => {
                await getUser().unwrap();
                goToResults();
              }, 1000);
            }, feedbackAnimationTimeout);
          }, 1000);
        } else {
          setTimeout(async () => {
            await getUser().unwrap();
            goToResults();
          }, 1000);
        }
      }, feedbackAnimationTimeout);
    } else if (
      checkedInitGameData.ladder_points[
        checkedLadderData.current_round_index - 1
      ]?.cushion
    ) {
      feedbackInProgress++;
      setTimeout(() => {
        setShowCushionSafe(true);
        setTimeout(() => {
          setShowCushionSafe(false);
        }, feedbackAnimationTimeout);
      }, (feedbackInProgress - 1) * feedbackAnimationTimeout);
    } else if (
      checkedLadderData.current_round_index === 1 &&
      checkedLadderData.opponent.can_advance
    ) {
      feedbackInProgress++;
      setShowLadderFeedback(true);
      setLadderFeedbackType(FeedbackType.Cushions);
      setShowRings(true);
      setTimeout(() => {
        setCanPositionRings(true);
      }, 3000);
      setTimeout(() => {
        setLadderFeedbackType(null);
        setShowLadderFeedback(false);
      }, feedbackAnimationTimeout);
    }
  };

  const handleStartTutorial = () => {
    dispatch(setTutorial({ show: "ladder", step: 0 }));
  };

  const renderLadderItems = (ladderItems: Array<LadderPointsValues>) => {
    return ladderItems?.map((ladderItem, index) => {
      const disabled =
        index > currentItem ||
        (ladderData?.finished && index === 1 && ladderData.player.can_advance);
      const active = currentItem === index;
      let buttonType: ButtonProps["buttonType"] = "ladder-white";

      const tutorialCushion =
        tutorial.show === "ladder" && (index === 4 || index === 1);

      // console.log("render", { index });
      // console.log("render", { disabled });
      // console.log("render", { active });
      // console.log("render", { currentItem });

      if (active && pendingResults && !ladderData?.finished) {
        buttonType = "ladder-yellow";
      } else if (active && readyToPlay) {
        buttonType = index === 0 ? "success-gold" : "success";
      } else if (index === 0) {
        buttonType = "gold";
      }

      return (
        <LadderButton
          refCallback={(el) => (ladderButtonRef.current[index] = el)}
          key={`ladder-item-${ladderItem.value}`}
          buttonType={buttonType}
          icon="diamonds"
          endIcon={
            tutorialCushion ||
            (ladderData &&
              ladderData.current_round_index > 1 &&
              ladderItem.cushion)
              ? "swimming-ring"
              : undefined
          }
          count={ladderItem.value}
          active={active}
          readyToPlay={readyToPlay}
          className={cx(active && "none-margin")}
          disabled={disabled}
          animation={true}
          animationSettings={{ delay: 0 }}
          handlePlay={handlePlay}
        ></LadderButton>
      );
    });
  };

  return (
    <div className="ladder-page">
      <Background type="music">
        <NavBar
          slotEnd="level"
          slotBeforeLogo={
            <img
              src="/img/icns/icon-info.png"
              alt=""
              className="start-tutorial-icon"
              onClick={handleStartTutorial}
            />
          }
        />
        <div id="ladder">
          <div className="ladder-content" ref={ladderContainerRef}>
            {ladderData && sortedLadderItems.length > 0
              ? renderLadderItems(sortedLadderItems)
              : null}

            {ladderData && (
              <>
                <motion.div
                  className="player-left"
                  initial="hidden"
                  animate="visible"
                  variants={variantsPlayer}
                  style={{ top: playersPosition[0] }}
                >
                  <Avatar
                    image={user ? user.character.avatar : mockAvatar}
                    left={
                      !pendingResults && ladderData?.current_round_index > 0 ? (
                        <Badge
                          type={ladderData.player.can_advance ? "win" : "lost"}
                          aspect="circle"
                        >
                          <img
                            src={
                              ladderData.player.can_advance
                                ? `../img/icns/verified.png`
                                : `../img/icns/cross.png`
                            }
                          />
                        </Badge>
                      ) : undefined
                    }
                  ></Avatar>
                </motion.div>

                <motion.div
                  className="player-right"
                  style={{
                    top: playersPosition[1],
                  }}
                  initial="hidden"
                  animate="visible"
                  variants={variantsPlayer}
                >
                  <div className={!opponentCanAdvance ? "disabled" : ""}>
                    <Avatar
                      image={opponentAvatar ?? mockAvatar}
                      right={
                        !pendingResults &&
                        ladderData?.current_round_index > 0 ? (
                          <Badge
                            type={
                              ladderData.opponent.can_advance ? "win" : "lost"
                            }
                            aspect="circle"
                          >
                            <img
                              src={
                                ladderData.opponent.can_advance
                                  ? `../img/icns/verified.png`
                                  : `../img/icns/cross.png`
                              }
                            />
                          </Badge>
                        ) : undefined
                      }
                    ></Avatar>
                  </div>
                </motion.div>
              </>
            )}
          </div>
        </div>

        {/* Ladder Modals */}
        <LadderFeedback
          type={FeedbackType.Great}
          show={showLadderFeedback && ladderFeedbackType === FeedbackType.Great}
          setShow={setShowLadderFeedback}
        />
        <LadderFeedback
          type={FeedbackType.Lost}
          show={showLadderFeedback && ladderFeedbackType === FeedbackType.Lost}
          setShow={setShowLadderFeedback}
        />
        <LadderFeedback
          type={FeedbackType.SafePoints}
          show={
            showLadderFeedback && ladderFeedbackType === FeedbackType.SafePoints
          }
          setShow={setShowLadderFeedback}
        />
        <LadderFeedback
          type={FeedbackType.Strike}
          show={
            showLadderFeedback && ladderFeedbackType === FeedbackType.Strike
          }
          reward={
            ladderPoints && ladderPoints.length > 0
              ? ladderPoints[ladderPoints?.length - 1].value
              : 0
          }
          setShow={setShowLadderFeedback}
        />
        <LadderFeedback
          type={FeedbackType.InstantWin}
          show={
            showLadderFeedback && ladderFeedbackType === FeedbackType.InstantWin
          }
          reward={prizeRef.current?.description[lang]}
          setShow={setShowLadderFeedback}
        />
        <LadderCushionSafe
          points={
            game?.ladder_points[game?.current_round_index - 1]?.value ?? 0
          }
          show={showCushionSafe}
          setShow={setShowCushionSafe}
        />
        <LadderFeedback
          type={FeedbackType.Cushions}
          show={
            showLadderFeedback && ladderFeedbackType === FeedbackType.Cushions
          }
          setShow={setShowLadderFeedback}
        />
        <motion.div
          layout
          variants={variantsCushions}
          initial="hidden"
          animate={showRings ? "visible" : ""}
          className="cushion-container"
          style={{
            left: "50%",
            top: canPositionRings
              ? ladderButtonRef.current[1]
                ? getTopFromMiddleOfElement(ladderButtonRef.current[1], true) +
                  35
                : 0
              : "50%",
            marginLeft: "86px",
            marginTop: "-35px",
          }}
          transition={{
            layout: {
              duration: 1,
            },
          }}
        >
          <motion.img
            className={`cushion-float cushion-1 ${
              showLadderFeedback && ladderFeedbackType === FeedbackType.Cushions
                ? "inside-modal"
                : ""
            }`}
            src="/img/icns/cushion-no-rotate.svg"
            alt=""
            variants={variantsRing1}
            initial="initial"
            animate={canPositionRings ? "final" : ""}
          />
        </motion.div>
        <motion.div
          layout
          variants={variantsCushions}
          initial="hidden"
          animate={showRings ? "visible" : ""}
          className="cushion-container"
          style={{
            left: "50%",
            top: canPositionRings
              ? ladderButtonRef.current[4]
                ? getTopFromMiddleOfElement(ladderButtonRef.current[4], true) +
                  11
                : 0
              : "50%",
            marginLeft: "86px",
            marginTop: "-11px",
          }}
          transition={{
            layout: {
              duration: 1,
            },
          }}
        >
          <motion.img
            className={`cushion-float cushion-2 ${
              showLadderFeedback && ladderFeedbackType === FeedbackType.Cushions
                ? "inside-modal"
                : ""
            }`}
            src="/img/icns/cushion-no-rotate.svg"
            alt=""
            variants={variantsRing2}
            initial="initial"
            animate={canPositionRings ? "final" : ""}
          />
        </motion.div>
      </Background>
    </div>
  );
}

export default Ladder;
