import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import cx from "classnames";
import * as Yup from "yup";
import { ErrorMessage, Field, Form, Formik } from "formik";

import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";
import {
  selectLanguage,
  selectTranslations,
} from "../../../shared/data-access/store/general/generalSlice";
import { useLoginOtpMutation } from "../../data-access/store/services/auth.service";
import { setMessage } from "../../../shared/data-access/store/message/messageSlice";
import { checkTranslationKey } from "../../../utils/translation";
import { Button } from "../../../shared/ui/button";
import { Title } from "../../../shared/ui/title";
import { Auth } from "../auth/auth";

import "./otp.scss";

type LocationState = {
  state: null | { msisdn: string };
};

export function Otp() {
  const { state } = useLocation() as LocationState;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [
    loginOtp,
    { data: loginOtpData, error: loginOtpError, isLoading: loginOtpIsLoading },
  ] = useLoginOtpMutation();
  // const [
  //   regenerateOtp,
  //   { isLoading: regenerateOtpIsLoading, isSuccess: regenerateOtpIsSuccess },
  // ] = useRegenerateOtpMutation();
  const lang = useAppSelector(selectLanguage);
  const translations = useAppSelector(selectTranslations);

  const OtpSchema = Yup.object({
    otp: Yup.string()
      .matches(
        /^[0-9]+$/,
        checkTranslationKey(
          translations[lang]?.The_OTP_code_must_be_only_digits,
          "The OTP code must be only digits."
        )
      )
      .min(
        6,
        checkTranslationKey(
          translations[lang]?.The_OTP_code_is_too_short,
          "The OTP code is too short."
        )
      )
      .max(
        6,
        checkTranslationKey(
          translations[lang]?.The_OTP_code_is_too_long,
          "The OTP code is too long."
        )
      )
      .required(
        checkTranslationKey(
          translations[lang]?.The_OTP_code_is_required,
          "The OTP code is required."
        )
      ),
  });

  useEffect(() => {
    if (!state?.msisdn) {
      navigate("/auth/sign-in");
    }
  }, []);

  // useEffect(() => {
  //   if (regenerateOtpIsSuccess) {
  //     dispatch(
  //       setMessage({
  //         content: checkTranslationKey(
  //           translations[lang]?.OTP_sent,
  //           "OTP sent"
  //         ),
  //         type: "success",
  //       })
  //     );
  //   }
  // }, [regenerateOtpIsSuccess]);

  useEffect(() => {
    if (loginOtpError) {
      const errorMsg =
        (loginOtpError as { data?: Error })?.data?.message ||
        checkTranslationKey(translations[lang]?.Error, "Error!");

      // if (loginOtpError.status === 401 && loginOtpError.data?.error) {
      //   errorMsg = "Bad credentials, try again";
      // }
      dispatch(setMessage({ content: errorMsg, type: "error" }));
    }
  }, [loginOtpError]);

  useEffect(() => {
    if (loginOtpData) {
      navigate("/");
    }
  }, [loginOtpData]);

  return (
    <div className="auth-page">
      <Auth
        className="auth-shell-content"
        header={
          <Title backURL="/auth/sign-in">
            {checkTranslationKey(translations[lang]?.OTP, "OTP")}
          </Title>
        }
      >
        <div className="form-title form-title-login">
          {checkTranslationKey(
            translations[lang]?.Enter_the_verification_code_you_received,
            "Enter the verification code you received"
          )}
        </div>
        <div className="form-container">
          <Formik
            initialValues={{ otp: "" }}
            validationSchema={OtpSchema}
            onSubmit={async (values) => {
              if (values.otp && state?.msisdn) {
                loginOtp({ msisdn: state.msisdn || "", otp: values.otp });
              }
            }}
          >
            {({ errors, touched }) => (
              <Form>
                <div
                  className={cx("form-input-row", {
                    danger: errors.otp && touched.otp,
                  })}
                >
                  <Field
                    autoComplete="off"
                    className="full-input"
                    type="text"
                    name="otp"
                    placeholder="• • • • • •"
                    maxLength={6}
                  />
                  <ErrorMessage name="otp">
                    {(msg) => (
                      <div className="error-msg">
                        <img
                          src="/img/icns/alert-fill.svg"
                          alt="alert"
                          className="color-svg"
                        />
                        {msg}
                      </div>
                    )}
                  </ErrorMessage>
                </div>

                <div>
                  <div className="submit">
                    <Button
                      buttonType="success"
                      type="submit"
                      value="Submit"
                      disabled={loginOtpIsLoading}
                    >
                      {loginOtpIsLoading
                        ? checkTranslationKey(
                            translations[lang]?.Signing_in,
                            "Signing in..."
                          )
                        : checkTranslationKey(
                            translations[lang]?.Sign_in,
                            "Sign in"
                          )}
                    </Button>
                  </div>
                  {/*<div className="forgot-area">*/}
                  {/*  <p className="or">*/}
                  {/*    {checkTranslationKey(translations[lang]?.Or, "Or")}*/}
                  {/*  </p>*/}
                  {/*  <Button*/}
                  {/*    type="button"*/}
                  {/*    buttonType="white"*/}
                  {/*    small*/}
                  {/*    disabled={regenerateOtpIsLoading}*/}
                  {/*    onClick={() => {*/}
                  {/*      regenerateOtp({ msisdn: state?.msisdn || "" });*/}
                  {/*    }}*/}
                  {/*  >*/}
                  {/*    {regenerateOtpIsLoading*/}
                  {/*      ? checkTranslationKey(*/}
                  {/*          translations[lang]?.Resending_OTP,*/}
                  {/*          "Resending OTP..."*/}
                  {/*        )*/}
                  {/*      : checkTranslationKey(*/}
                  {/*          translations[lang]?.Resend_OTP,*/}
                  {/*          "Resend OTP"*/}
                  {/*        )}*/}
                  {/*  </Button>*/}
                  {/*</div>*/}
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Auth>
    </div>
  );
}

export default Otp;
