import { Background } from "../../../shared/ui/background";
import { NavBar } from "../../../shared/ui/navbar";
import { Title } from "../../../shared/ui/title";
import { AchievementBadge } from "../../ui/achievements";
import "./badges.scss";
import { useGetAchievementsQuery } from "../../data-access/store/services/badges.service";
import { useAppSelector } from "../../../hooks/hooks";
import { selectUser } from "../../../auth/data-access/store/authSlice";
import { AchievementsResponse } from "../../../shared/interfaces/general.types";
import {
  selectLanguage,
  selectTranslations,
} from "../../../shared/data-access/store/general/generalSlice";
import { format } from "date-fns";
import { checkTranslationKey } from "../../../utils/translation";
import useAmbientSound from "../../../utils/hooks/use-ambient-sound";

export function BadgesView() {
  const { data: achievementsData } = useGetAchievementsQuery();
  const user = useAppSelector(selectUser);
  const lang = useAppSelector(selectLanguage);
  const translations = useAppSelector(selectTranslations);

  const userAchievements = user?.character?.achievements || [];

  useAmbientSound("home");

  const renderAchievements = (achievements: AchievementsResponse["items"]) => {
    return achievements?.map((achievement) => {
      const unlocked = userAchievements.find(
        (userAchievement) => userAchievement.id === achievement.id
      );

      let unlockDate = "";

      if (unlocked) {
        const dateStr = unlocked?.unlockDate;
        const [dateValues] = dateStr.split(" ");
        const date = new Date(dateValues);
        unlockDate = format(new Date(date), "d MMM, yyyy");
      }

      return (
        <AchievementBadge
          key={achievement.id}
          badgeType={unlocked ? "unlocked" : "locked"}
          badge={achievement.image}
          subtitle={unlockDate || ""}
          description={achievement.description[lang]}
        >
          {achievement.name[lang]}
        </AchievementBadge>
      );
    });
  };

  return (
    <div className="badges-container">
      <Background type="geography">
        <NavBar hasAvatar />
        <div className="badges-content">
          <Title backURL="/profile">
            {checkTranslationKey(
              translations[lang]?.Achievements,
              "Achievements"
            )}
          </Title>
          <div className="achievement-container">
            {achievementsData && achievementsData.items
              ? renderAchievements(achievementsData.items)
              : null}
          </div>
        </div>
      </Background>
    </div>
  );
}

export default BadgesView;
