import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import cx from "classnames";
import { Background } from "../../../shared/ui/background";
import { Button } from "../../../shared/ui/button";
import { NavBar } from "../../../shared/ui/navbar";
import { Title } from "../../../shared/ui/title";
import "./profile-edit.scss";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";
import { selectUser } from "../../../auth/data-access/store/authSlice";
import { useGetAvatarsQuery } from "../../../shared/data-access/store/general/services/general.service";
import { AvatarsResponse } from "../../../shared/interfaces/general.types";
import { usePutUserMutation } from "../../../auth/data-access/store/services/auth.service";
import { useEffect } from "react";
import { setMessage } from "../../../shared/data-access/store/message/messageSlice";
import {
  selectLanguage,
  selectTranslations,
} from "../../../shared/data-access/store/general/generalSlice";
import { checkTranslationKey } from "../../../utils/translation";
import { useNavigate } from "react-router-dom";
import useAmbientSound from "../../../utils/hooks/use-ambient-sound";

export function EditProfile() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUser);
  const { data: avatarData } = useGetAvatarsQuery();
  const [
    editUser,
    {
      isLoading: editUserIsLoading,
      error: editUserError,
      isSuccess: editUserIsSuccess,
    },
  ] = usePutUserMutation();
  const lang = useAppSelector(selectLanguage);
  const translations = useAppSelector(selectTranslations);
  useAmbientSound("home");

  useEffect(() => {
    if (editUserError) {
      const errorMsg =
        (editUserError as { data?: Error })?.data?.message ||
        checkTranslationKey(translations[lang]?.Error, "Error!");
      if (errorMsg) {
        dispatch(setMessage({ content: errorMsg, type: "error" }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editUserError]);

  useEffect(() => {
    if (editUserIsSuccess) {
      navigate("/profile");
      dispatch(
        setMessage({
          content: checkTranslationKey(
            translations[lang]?.User_updated,
            "User updated"
          ),
          type: "success",
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editUserIsSuccess]);

  const ProfileSchema = Yup.object().shape({
    nickname: Yup.string().max(
      15,
      checkTranslationKey(
        translations[lang]?.Can_t_be_longer_than_15_characters,
        "Can't be longer than 15 characters"
      )
    ),
  });
  return (
    <div className="edit-profile">
      <Background type="geography">
        <NavBar hasAvatar />
        <div className="profile-content">
          <Title backURL="/profile">
            {checkTranslationKey(
              translations[lang]?.Edit_Profile,
              "Edit Profile"
            )}
          </Title>
          <Formik
            validationSchema={ProfileSchema}
            initialValues={{
              nickname: user?.character?.nickname || "",
              avatar: user?.character?.avatar || "",
            }}
            onSubmit={(values) => {
              editUser({
                character: { nickname: values.nickname, avatar: values.avatar },
              });
            }}
          >
            {({ values, errors, touched }) => {
              const renderAvatars = (avatars: AvatarsResponse["items"]) => {
                return avatars?.map((avatar) => {
                  return (
                    <label className="avatars" key={avatar}>
                      <Field
                        type="radio"
                        name="avatar"
                        value={avatar}
                        checked={values.avatar === avatar}
                      />
                      <img src={avatar} alt="Avatar" />
                    </label>
                  );
                });
              };

              return (
                <Form>
                  <div className="edit-profile-cards">
                    <div className="card">
                      <label>
                        {checkTranslationKey(
                          translations[lang]?.Nickname,
                          "Nickname"
                        )}
                      </label>
                      <div
                        className={cx("input-wrapper", {
                          danger: errors.nickname && touched.nickname,
                        })}
                      >
                        <Field
                          className="form-control justify-content"
                          type="text"
                          name="nickname"
                          placeholder={checkTranslationKey(
                            translations[lang]?.Your_nickname,
                            "Your nickname"
                          )}
                          autoComplete="off"
                          maxLength={15}
                          required
                        />
                        <ErrorMessage name="nickname">
                          {(msg) => (
                            <div className="error-msg">
                              <img
                                src="../img/icns/alert-fill.svg"
                                alt="alert"
                                className="color-svg"
                              />
                              {msg}
                            </div>
                          )}
                        </ErrorMessage>
                      </div>
                    </div>
                    <div className="card card-margin-last">
                      <label>
                        {checkTranslationKey(
                          translations[lang]?.Select_your_avatar_from_the_list,
                          "Select your avatar from the list"
                        )}
                      </label>
                      <div className="avatar-grid">
                        {avatarData && avatarData.items
                          ? renderAvatars(avatarData.items)
                          : null}
                      </div>
                    </div>
                  </div>
                  <div className="button-position">
                    <Button
                      type="submit"
                      buttonType="success"
                      disabled={editUserIsLoading}
                    >
                      {checkTranslationKey(
                        translations[lang]?.Save_profile,
                        "Save profile"
                      )}
                    </Button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </Background>
    </div>
  );
}

export default EditProfile;
