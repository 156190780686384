import { Button } from "../../../shared/ui/button";
import { Title } from "../../../shared/ui/title";
import { Auth } from "../auth/auth";
import cx from "classnames";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";

import {
  useLazyGetUserQuery,
  useSubscribeDirectMutation,
} from "../../data-access/store/services/auth.service";
import { useEffect } from "react";
import { setMessage } from "../../../shared/data-access/store/message/messageSlice";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";
import {
  selectInit,
  selectLanguage,
  selectTranslations,
} from "../../../shared/data-access/store/general/generalSlice";
// import { useNavigate } from "react-router-dom";
import { checkTranslationKey } from "../../../utils/translation";
import { UpstreamServiceBanner } from "../../../shared/ui/upstream-service-banner";
import { useLocation, useNavigate } from "react-router-dom";
import { selectUser } from "../../data-access/store/authSlice";

export function Pin() {
  const dispatch = useAppDispatch();
  // const navigate = useNavigate();
  const [
    subscribeDirect,
    {
      isLoading: subscribeDirectIsLoading,
      isSuccess: subscribeDirectIsSuccess,
      error: subscribeDirectError,
    },
  ] = useSubscribeDirectMutation();
  const init = useAppSelector(selectInit);
  const lang = useAppSelector(selectLanguage);
  const translations = useAppSelector(selectTranslations);
  const navigate = useNavigate();
  const { state } = useLocation();
  const user = useAppSelector(selectUser);
  const [getUser] = useLazyGetUserQuery();

  useEffect(() => {
    if (subscribeDirectError) {
      const errorMsg =
        (subscribeDirectError as { data?: Error })?.data?.message ||
        checkTranslationKey(translations[lang]?.Error, "Error!");
      if (errorMsg) {
        dispatch(setMessage({ content: errorMsg, type: "error" }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscribeDirectError]);

  useEffect(() => {
    if (subscribeDirectIsSuccess) {
      if (user) {
        getUser();
      } else {
        navigate("/auth/login");
      }
    }
  }, [subscribeDirectIsSuccess]);

  const pinMaxLength = init?.users?.pin?.maxLength || 6;
  const pinMinLength = init?.users?.pin?.minLength || 4;

  const SignInSchema = Yup.object().shape({
    pin_subscribe: Yup.string()
      .min(
        pinMinLength,
        checkTranslationKey(
          translations[lang]?.The_pin_is_too_short,
          "The password is too short."
        )
      )
      .max(
        pinMaxLength,
        checkTranslationKey(
          translations[lang]?.The_pin_is_too_long,
          "The password is too long."
        )
      )
      .required(
        checkTranslationKey(
          translations[lang]?.The_pin_is_required,
          "The password is required."
        )
      ),
  });

  return (
    <div className="auth-page subscribe-page">
      <Auth
        className="auth-shell-content"
        header={
          <Title>
            {checkTranslationKey(translations[lang]?.Subscribe, "Subscribe")}
          </Title>
        }
      >
        <div className="form-title form-title-login">
          {checkTranslationKey(
            translations[lang]?.Create_your_account_and_subscribe,
            "Create your account and subscribe"
          )}
        </div>
        <div className="form-container">
          <Formik
            initialValues={{ pin_subscribe: "" }}
            validationSchema={SignInSchema}
            onSubmit={(values) => {
              subscribeDirect({
                msisdn: state as string,
                password: values.pin_subscribe,
              });
            }}
          >
            {({ errors, touched }) => (
              <Form>
                <div
                  className={cx("form-input-row", {
                    danger: errors.pin_subscribe && touched.pin_subscribe,
                    success: !errors.pin_subscribe,
                  })}
                >
                  <label className="label" htmlFor="pin_subscribe">
                    Please enter your password
                  </label>
                  <Field
                    type="text"
                    name="pin_subscribe"
                    className="full-input"
                    placeholder={checkTranslationKey(
                      translations[lang]?.Please_enter_your_pin,
                      "Please enter your password"
                    )}
                    autoComplete="off"
                    maxLength={pinMaxLength}
                    required
                  />
                  <ErrorMessage name="pin_subscribe">
                    {(msg) => (
                      <div className="error-msg">
                        <img
                          src="/img/icns/alert-fill.svg"
                          alt="alert"
                          className="color-svg"
                        />
                        {msg}
                      </div>
                    )}
                  </ErrorMessage>
                </div>

                <div>
                  <div className="submit">
                    <Button
                      buttonType="success"
                      type="submit"
                      value="Submit"
                      disabled={subscribeDirectIsLoading}
                    >
                      {subscribeDirectIsLoading
                        ? checkTranslationKey(
                            translations[lang]?.Submitting,
                            "Submitting..."
                          )
                        : checkTranslationKey(translations[lang]?.Next, "Next")}
                    </Button>
                  </div>
                  <p className="cost">Cost: XX/day</p>
                  <div className="terms-area">
                    {checkTranslationKey(
                      translations[lang]?.By_entering_the_pin_you_confirm,
                      "By entering the password you confirm subscription and agree to the"
                    )}{" "}
                    <a href={init?.content?.terms[lang]} target="_blank">
                      {checkTranslationKey(
                        translations[lang]?.Terms_and_Conditions,
                        "Terms and Conditions"
                      )}
                    </a>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Auth>
      <div className="stream-service">
        <UpstreamServiceBanner />
      </div>
    </div>
  );
}

export default Pin;
